import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Spinner } from "reactstrap";
import ImageCrop from "../../pages/Map/ImageCrop";
import { BsXLg } from "react-icons/bs";
import api from "../../api/api";
import "./ModalPoleComponents.css";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";

const classNames = {
  CROSS_HEAD: "Cruzeta",
  DROP_OUT_FUSE: "Chave",
  FIBER_OPTIC_JUNCTION_BOX: "Caixa junção fibra óptica",
  INSULATOR: "Isolador",
  LAMP: "Lâmpada",
  LAMP_ARM: "Braço de luminária",
  TELECOM_BOX: "Caixa de telecom",
  TRANSFORMER: "Transformador",
  WIRE_RESERVE: "Reserva de fios",
};

const classColors = ["#56e02e", "#efe52c", "#cb00a8", "#653b00", "#f7f7c0"];

const ModalPoleComponents = ({
  pointDetailOffcanvas,
  openDetailsModal,
  setViewLightComponents,
}) => {
  const [objects, setObjects] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [handleFilter, setHandleFilter] = useState(objects);
  const [loadingComponents, setLoadingComponents] = useState(true);
  const [itemToDelete, setItemToDelete] = useState();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const typeCounts = objects.reduce((counts, item) => {
    // Check if the className exists in the counts object
    counts[item.className] = (counts[item.className] || 0) + 1;
    return counts;
}, {});

  useEffect(() => {
    setHandleFilter(objects);
  }, [objects]);

  //lida com o clique dos botões de filtro
  const handleFilterClick = (type) => {
    if (type === "all") {
      setHandleFilter(objects);
    } else {
      const filteredData = objects.filter((item) => {
        return item.className === type;
      });
      setHandleFilter(filteredData);
    }
    setFilterType(type);
  };

  useEffect(() => {
    const getObjects = async (point) => {
      try {
        const { data } = await api.get(`/detected-objects/${point}/children`);

        const updatedData = await Promise.all(
          data.map(async (i) => {
            i.detection = await setDetectionsObj(i.id);
            return i;
          })
        );

        setObjects(updatedData);
        setLoadingComponents(false);
      } catch (error) {
        console.error(error);
      }
    };

    getObjects(pointDetailOffcanvas.point.id);
    handleFilterClick("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointDetailOffcanvas]);

  async function setDetectionsObj(objectId) {
    try {
      const { data } = await api.get(
        `/detections-obb/detected-objects/${objectId}`
      );

      data.content.sort((a, b) => calcBbArea(b) - calcBbArea(a));

      return data.content;
    } catch (error) {
      console.error(error);
    }
  }

  function calcBbArea(detection) {
    let bb_width = detection.x2 - detection.x1;
    let bb_height = detection.y4 - detection.y1;

    return bb_width * bb_height;
  }

  function handleDeleteClick(item) {
    setItemToDelete(item);
    toggle();
  }

  const deleteObject = async () => {
    try {
      await api.delete(`/detected-objects/${itemToDelete?.id}`);
      const updatedObjects = objects.filter(
        (object) => object.id !== itemToDelete.id
      );
      setObjects(updatedObjects);
    } catch (e) {
      console.log(e);
    } finally {
      toggle();
    }
  };

  return (
    <>
      <ModalConfirmation
        toggle={toggle}
        modal={modal}
        text={`Tem certeza que deseja excluir o objeto #${itemToDelete?.id}?`}
        confirmFunction={() => deleteObject(itemToDelete)}
      />
      <Card
        className="modal-pole-components-card"
        style={{
          left: "7%",
          top: "10%",
          position: "absolute",
          zIndex: "1030",
          display: "block",
          overflowX: "scroll",
          maxHeight: "85%",
          maxWidth: "50%",
        }}
      >
        <CardTitle
          style={{
            position: "sticky",
            top: 0,
            zIndex: "2000",
            backgroundColor: "white",
          }}
        >
          <div className="d-flex align-items-center p-3 gap-1">
            <Col style={{ fontSize: "1.2em" }}>Componentes do Poste</Col>

            <BsXLg
              onClick={() => setViewLightComponents(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <hr className="m-0" />
        </CardTitle>
        <CardBody className="">
          <div>
            <div className="filter-options-container">
              <div
                className="filter-option"
                onClick={() => handleFilterClick("all")}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    border:
                      filterType === "all" ? "3px solid black" : "2px #FDF5E6",
                  }}
                />
                <strong>Todos ({objects.length || 0})</strong>
              </div>

              {objects
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex((i) => i.className === item.className)
                )
                .map((i, index) => (
                  <div
                    key={Math.random()}
                    className="filter-option"
                    onClick={() => handleFilterClick(i.className)}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: classColors[index] || "#ccc",
                        border:
                          filterType === i.className
                            ? "2px solid black"
                            : "2px #FDF5E6",
                      }}
                    />
                    <strong>{`${classNames[i.className]} (${
                      typeCounts[i.className] || 0
                    })`}</strong>
                  </div>
                ))}
            </div>

            {loadingComponents && (
              <div className="m-auto text-center">
                <Spinner
                  color="primary"
                  style={{
                    margin: "auto",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  Loading...
                </Spinner>
              </div>
            )}

            <div className="components-container">
              {handleFilter?.map((item, index) => (
                <div
                  className="components-card"
                  key={Math.random()}
                  style={{
                    border: "none",
                  }}
                >
                  <div
                    className="div-canvas"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      openDetailsModal(item.fatherObjectId, item.detection);
                    }}
                  >
                    <ImageCrop
                      imageSrc={item.detection[0]?.image.path_low}
                      detectionData={item.detection[0]}
                    />
                  </div>
                  <div className="components-info-column">
                    <div>
                      <div>
                        <strong>{classNames[item.className]}</strong> #{item.id}
                      </div>
                    </div>
                    
                    {item.attributes.led != null && (
                      <div>
                        <div>
                          <strong>Led:</strong>{" "}
                          <span>{item.attributes.led ? "Sim" : "Não"}</span>
                        </div>
                      </div>
                    )}
                    {item.attributes.allDayOn != null && (
                      <div>
                        <div>
                          <strong>Ligada 24Hrs:</strong>{" "}
                          <span>{item.attributes.allDayOn ? "Sim" : "Não"}</span>
                        </div>
                      </div>
                    )}
                    {item.attributes.length != null && (
                      <div>
                        <div>
                          <strong>Comprimento:</strong>{" "}
                          <span>{item.attributes.length.toFixed(2)} m</span>
                        </div>
                      </div>
                    )}
                    {item.height != null && (
                      <div>
                        <div>
                          <strong>Altura:</strong>{" "}
                          <span>{item.height.toFixed(2)} m</span>
                        </div>
                      </div>
                    )}
                    {item.objectType !== "LAMP_ARM" &&
                      item.distanceToGround != null && (
                        <div>
                          <div>
                            <strong>Distância do chão:</strong>{" "}
                            <span>{item.distanceToGround.toFixed(2)} m</span>
                          </div>
                        </div>
                      )}
                      {item.className === "LAMP" &&
                      item.distanceToFather != null && (
                        <div>
                          <div>
                            <strong>Projeção horizontal:</strong>{" "}
                            <span>{item.distanceToFather.toFixed(2)} m</span>
                          </div>
                        </div>
                      )}
                    
                  </div>
                  <div>
                    <small
                      className="btn btn-danger remove-btn"
                      onClick={() => handleDeleteClick(item)}
                    >
                      Remover
                    </small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ModalPoleComponents;
