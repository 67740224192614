import React, { useState } from "react";
import {
  Button,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Modal,
  ModalHeader,
} from "reactstrap";
import PhotoSphereViewer from "../PhotoSphereViewer/PhotoSphereViewer";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import ModalMessage from "../ModalMessage/ModalMessage";

const GalleryNewPole = ({
  imageArray,
  isOpen,
  toggle,
  coordinates,
  setActivePointSupervision,
  toggleSupervisionMode,
  toggleModalNewPole,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [boundingBox, setBoundingBox] = useState([]);
  const [modalConfimation, setModalConfirmation] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [message, setMessage] = useState("");

  let { id: projectId } = useParams();

  const toggleModalConfirmation = () => setModalConfirmation(!modalConfimation);

  const toggleModalMessage = () => setModalMessage(!modalMessage);

  const navigate = async (nextIndex) => {
    if (animating) return;
    setActiveIndex(nextIndex);
  };

  const next = () =>
    navigate(activeIndex === imageArray.length - 1 ? 0 : activeIndex + 1);
  const previous = () =>
    navigate(activeIndex === 0 ? imageArray.length - 1 : activeIndex - 1);
  const goToIndex = (newIndex) => navigate(newIndex);

  const saveAnnotation = async () => {
    if (boundingBox.length !== 4) {
      setMessage("4 pontos devem ser selecionados!");
      toggleModalMessage();
      return;
    }

    const newObject = {
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      projectId: projectId,
      className: "POLE"
    };

    const sortedBoundingBox = sortBoundingBoxCoordinates(boundingBox);

    try {
      const { data } = await api.post(`/detected-objects`, newObject);

      const detection = {
        detectedObjectId: data.id,
        image: imageArray[activeIndex],
        x1: yawToNormalizedX(sortedBoundingBox[0][0]),
        y1: pitchToNormalizedY(sortedBoundingBox[0][1]),
        x2: yawToNormalizedX(sortedBoundingBox[1][0]),
        y2: pitchToNormalizedY(sortedBoundingBox[1][1]),
        x3: yawToNormalizedX(sortedBoundingBox[2][0]),
        y3: pitchToNormalizedY(sortedBoundingBox[2][1]),
        x4: yawToNormalizedX(sortedBoundingBox[3][0]),
        y4: pitchToNormalizedY(sortedBoundingBox[3][1]),
        objectType: "pole",
        addedManually: true,
      };

      try {
        await api.post("detections-obb", detection);
        setMessage(
          "Poste criado com sucesso, você será redirecionado para o modal de anotação de componentes!"
        );
        toggleModalMessage();
        setActivePointSupervision(data);

        setTimeout(() => {
          toggle();
          toggleModalConfirmation();
          toggleSupervisionMode();
          toggleModalNewPole();
        }, 3000);
      } catch (error) {
        setMessage("Error ao criar detecção!");
        toggleModalMessage();
      }
    } catch (error) {
      setMessage("Error ao criar poste!");
      toggleModalMessage();
    }
  };

  function map(x, inMin, inMax, outMin, outMax) {
    x = Math.min(Math.max(x, inMin), inMax);
    return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  function yawToNormalizedX(yaw) {
    yaw =
      ((((yaw + Math.PI) % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI)) -
      Math.PI;
    return map(yaw, -Math.PI, Math.PI, 0, 1);
  }

  function pitchToNormalizedY(pitch) {
    return 1 - map(pitch, -Math.PI / 2, Math.PI / 2, 0, 1);
  }

  function sortBoundingBoxCoordinates(boundingBox) {
    boundingBox.sort((a, b) => b[1] - a[1]);

    let upperPairs = boundingBox.slice(0, 2);
    let lowerPairs = boundingBox.slice(2, 4);

    upperPairs.sort((a, b) => a[0] - b[0]);
    lowerPairs.sort((a, b) => a[0] - b[0]);

    const sortedBoundingBox = [
      upperPairs[0],
      upperPairs[1],
      lowerPairs[1],
      lowerPairs[0],
    ];

    return sortedBoundingBox;
  }

  return (
    <>
      {modalConfimation && (
        <ModalConfirmation
          toggle={toggleModalConfirmation}
          modal={modalConfimation}
          text="Deseja adicionar este poste?"
          confirmFunction={saveAnnotation}
        />
      )}

      {modalMessage && (
        <ModalMessage
          isOpen={modalMessage}
          message={message}
          toggle={toggleModalMessage}
        />
      )}

      <Modal isOpen={isOpen} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>
          <Button onClick={toggleModalConfirmation}>Salvar</Button>
        </ModalHeader>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={false}
        >
          <CarouselIndicators
            items={imageArray}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {imageArray.map((item, idx) => (
            <CarouselItem
              key={item.id}
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              active={idx === activeIndex}
            >
              <PhotoSphereViewer
                containerId={`viewer-${idx}`}
                imgSrc={item}
                height={"75vh"}
                annotation={true}
                annotationType={"pole"}
                setBoundingBox={setBoundingBox}
              />
            </CarouselItem>
          ))}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </Modal>
    </>
  );
};

export default GalleryNewPole;
