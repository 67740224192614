import React, { useEffect, useRef, useState } from "react";
import {
  Marker,
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
  Polygon,
  Polyline,
  FeatureGroup,
} from "react-leaflet";
import Leaflet from "leaflet";
import DraggableMarker from "../DraggableMarker/DraggableMarker";
import api from "../../api/api";
import DraggableMapMarker from "../DraggableMapMarker/DraggableMapMarker";
import { EditControl } from "react-leaflet-draw";
import "./MapContainerComponent.css";
import ModalDragMapPoints from "../ModalDragMapPoints/ModalDragMapPoints";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import ModalMessage from "../ModalMessage/ModalMessage";
import L from "leaflet";
import * as turf from "@turf/turf";

const colorLayersList = JSON.parse(process.env.REACT_APP_COLOR_LAYERS_MAP);
const defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;
const defaultTreePointColor = process.env.REACT_APP_DEFAULT_TREE_POINT_COLOR;
const defaultCableSpacersColor =
  process.env.REACT_APP_DEFAULT_CABLE_SPACER_POINT_COLOR;
const defaultRadiusMarker = process.env.REACT_APP_DEFAULT_POINT_RADIUS;

const minZoomToGroupPoints = process.env.REACT_APP_MIN_ZOOM_TO_GROUP_POINTS;

const MapContainerComponent = ({
  zoom, // Recebendo zoom como prop
  baseMapSource,
  areaOfInterest,
  areasOfInterestActive,
  enablePointsView,
  enableTreePointsView,
  enableCableSpacerPointsView,
  newPointPreviewMap,
  setHideLeftMenu,
  setOffCanvas,
  setPointDetailOffcanvas,
  mapPoints,
  trees,
  cableSpacers,
  newPointPosition,
  setNewPointPosition,
  activePointSupervision,
  setActivePointSupervision,
  previewImagesNewPoint,
  previewImagesVisible,
  searchBarValue,
  setLoading,
  carPathMapPoints,
  carPath,
  moveMapPoints,
  setMapPoints,
  setTrees,
  setCableSpacers,
  offCanvas,
  modalFilterPoleComponents,
}) => {
  const [map, setMap] = useState(null);
  const [centerAuto, setCenterAuto] = useState(true);
  const [zoomLevelInfo, setZoomLevelInfo] = useState(zoom); // Utilize o zoom inicial recebido via props
  const [filteredMapPoints, setFilteredMapPoints] = useState([]);
  const [filteredTrees, setFilteredTrees] = useState([]);
  const [filteredCableSpacers, setFilteredCableSpacers] = useState([]);
  const [mapBounds, setMapBounds] = useState(null);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [pointsToBeSaved, setPointsToBeSaved] = useState([]);
  const [modalMessage, setModalMessage] = useState(false);
  const [message, setMessage] = useState("");

  const toggleModalConfirmation = () =>
    setModalConfirmation(!modalConfirmation);

  const toggleModalMessage = () => setModalMessage(!modalMessage);

  const mapPointsRef = useRef(mapPoints);
  const treesRef = useRef(trees);
  const cableSpacersRef = useRef(cableSpacers);
  const drawnLayersRef = useRef(new Leaflet.FeatureGroup());

  const markersRef = useRef({});

  const handleMoveEnd = () => {
    const bounds = map.getBounds();
    setMapBounds(bounds);
  };

  useEffect(() => {
    if (map != null) {
      map.on("moveend", handleMoveEnd);

      return () => {
        map.off("moveend", handleMoveEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    const filterPoints = () => {
      setFilteredMapPoints(
        mapPoints.filter((point) =>
          point.id.toString().includes(searchBarValue)
        )
      );
      setFilteredTrees(
        trees.filter((tree) => tree.id.toString().includes(searchBarValue))
      );
      setFilteredCableSpacers(
        cableSpacers.filter((cableSpacer) =>
          cableSpacer.id.toString().includes(searchBarValue)
        )
      );
    };

    filterPoints();
  }, [searchBarValue, mapPoints, trees, cableSpacers]);

  useEffect(() => {
    const fetchData = async () => {
      if (map != null && mapPoints.length > 0 && searchBarValue.length > 3) {
        const allFilteredPoints = [
          ...filteredMapPoints.map((point) => ({ ...point, type: "poles" })),
          ...filteredTrees.map((tree) => ({ ...tree, type: "trees" })),
          ...filteredCableSpacers.map((cableSpacer) => ({
            ...cableSpacer,
            type: "cable-spacers",
          })),
        ];

        if (allFilteredPoints.length === 1) {
          const point = allFilteredPoints[0];

          setFocusPoint(point);
          setPointDetailOffcanvas({
            point: point,
            type: point.type,
          });
          setOffCanvas(true);
          setHideLeftMenu(true);

          map.setView([point.latitude, point.longitude], 16);

          if (point.type === "poles") {
            filteredMapPoints.filter((m) => m.id === point.id)[0][
              "radius"
            ] = 15;
          }

          if (point.type === "trees") {
            filteredTrees.filter((m) => m.id === point.id)[0]["radius"] = 15;
          }

          if (point.type === "cable-spacers") {
            filteredTrees.filter((m) => m.id === point.id)[0]["radius"] = 15;
          }
        }

        if (allFilteredPoints.length === 0) {
          try {
            setLoading(true);
            const { data } = await api.get(
              `/detected-objects/components/${searchBarValue}`
            );

            if (data.length > 0) {
              const point = data[0];

              setFilteredMapPoints(data);
              setFocusPoint(point);
              setPointDetailOffcanvas({
                point: point,
                type: "poles",
                viewComponents: true,
              });
              setOffCanvas(true);
              setHideLeftMenu(true);

              map.setView([point.latitude, point.longitude], 16);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredCableSpacers,
    filteredMapPoints,
    filteredTrees,
    map,
    searchBarValue,
  ]);

  useEffect(() => {
    if (map != null && activePointSupervision != null) {
      setFocusPoint(activePointSupervision);
      setPointDetailOffcanvas({
        point: activePointSupervision,
        type: "poles",
      });
      setOffCanvas(true);
      setHideLeftMenu(true);

      if (zoomLevelInfo <= minZoomToGroupPoints) {
        map.setView(
          [activePointSupervision.latitude, activePointSupervision.longitude],
          minZoomToGroupPoints
        );
      }
      map.setView([
        activePointSupervision.latitude,
        activePointSupervision.longitude,
      ]);

      if (!mapPoints.some((m) => m.id === activePointSupervision.id)) {
        mapPoints.push(activePointSupervision);
      }

      mapPoints.filter((m) => m.id === activePointSupervision.id)[0][
        "radius"
      ] = 15;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePointSupervision]);

  const selectedPointIcon = new Leaflet.Icon({
    iconUrl: "/imgs/selected_blue_point.svg",
    iconAnchor: [22, 62],
    popupAnchor: [10, -44],
    iconSize: [44, 72],
  });

  const selectedTreeIcon = new Leaflet.Icon({
    iconUrl: "/imgs/selected_green_point.svg",
    iconAnchor: [22, 62],
    popupAnchor: [10, -44],
    iconSize: [44, 72],
  });

  const selectedCableSpacerIcon = new Leaflet.Icon({
    iconUrl: "/imgs/selected_orange_point.svg",
    iconAnchor: [22, 62],
    popupAnchor: [10, -44],
    iconSize: [44, 72],
  });

  const newPoleIcon = new Leaflet.Icon({
    iconUrl: "/imgs/selected_red_point.svg",
    iconAnchor: [22, 62],
    popupAnchor: [10, -44],
    iconSize: [44, 72],
  });

  const createCustomIcon = (color) => {
    const customSvgIcon = `
      <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="15" stroke="${color}" stroke-width="0" fill="transparent" />
        <circle cx="20" cy="20" r="${defaultRadiusMarker}" fill="${color}" />
      </svg>
    `;

    return Leaflet.divIcon({
      html: customSvgIcon,
      className: "custom-icon",
      iconSize: [40, 40],
      iconAnchor: [20, 20],
    });
  };

  let centerTag = {
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  };

  const getPointsMarkers = (points, icon, color, type) => {
    let pointMarkers = [];

    if (mapBounds) {
      points = points.filter((point) => {
        const { latitude, longitude } = point;
        return mapBounds.contains([latitude, longitude]);
      });
    }

    points.map((point) => {
      const handleClick = (draggable) => {
        if (draggable) return;

        if (type === "poles") {
          setActivePointSupervision(point);
        }

        setFocusPoint(point);

        setPointDetailOffcanvas({
          point,
          type,
          viewComponents: modalFilterPoleComponents,
        });

        setOffCanvas(true);
        setHideLeftMenu(true);

        if (zoomLevelInfo <= minZoomToGroupPoints) {
          map.setView([point.latitude, point.longitude], minZoomToGroupPoints);
        }
        map.setView([point.latitude, point.longitude]);
      };

      if (!offCanvas) {
        point.radius = false;
      }

      pointMarkers.push(
        <DraggableMapMarker
          key={Math.random()}
          point={point}
          icon={icon}
          color={color}
          clickFunction={handleClick}
          draggable={point.draggable}
          onDragStart={handleMarkerDragStart}
          onDragEnd={handleMarkerDragEnd}
          onDrag={handleMarkerDrag}
          markerRef={markersRef}
        />
      );

      if (type === "poles") {
        if (!centerTag.latMax || point.latitude < centerTag.latMax)
          centerTag.latMax = point.latitude;
        if (!centerTag.latMin || point.latitude > centerTag.latMin)
          centerTag.latMin = point.latitude;
        if (!centerTag.lngMax || point.longitude < centerTag.lngMax)
          centerTag.lngMax = point.longitude;
        if (!centerTag.lngMin || point.longitude > centerTag.lngMin)
          centerTag.lngMin = point.longitude;

        pointMarkers.push(
          <RecenterAutomatically
            key={Math.random()}
            lat={(centerTag.latMax + centerTag.latMin) / 2}
            lng={(centerTag.lngMax + centerTag.lngMin) / 2}
          />
        );
      }

      return null;
    });
    return pointMarkers;
  };

  const setFocusPoint = (point) => {
    let mapMarkers = [...mapPoints, ...trees, ...cableSpacers];

    if (mapMarkers.length > 0) {
      mapMarkers.map((value) => {
        value.radius = false;
        return value;
      });
      if (point) {
        point.radius = 15;
      }
    }
  };

  const RecenterAutomatically = ({ lat, lng }) => {
    let diffLat = centerTag.latMax - centerTag.latMin;
    if (diffLat < 0) diffLat *= -1;
    const map = useMap();
    useEffect(() => {
      if (centerAuto) {
        let diffLat = centerTag.latMax - centerTag.latMin;

        if (diffLat < 0) diffLat *= -1;

        let zoomLevel = 17;

        if (diffLat * 10000 < 10) zoomLevel = 19;
        else if (diffLat * 10000 < 70) zoomLevel = 17;
        else if (diffLat * 10000 < 150) zoomLevel = 16;
        else if (diffLat * 10000 < 300) zoomLevel = 15;
        else if (diffLat * 10000 < 500) zoomLevel = 14;
        else if (diffLat * 10000 < 1000) zoomLevel = 13;
        else if (diffLat * 10000 < 8000) zoomLevel = 11;
        else if (diffLat * 10000 < 10000) zoomLevel = 8;
        else if (diffLat * 10000 < 35000) zoomLevel = 6;
        else zoomLevel = 2;

        let newLat = lat;
        let newLng = lng;
        if (lat === 0 || lng === 0) {
          // Lat e Lng do espírito santo
          newLat = -19.54708428614826;
          newLng = -40.513774803548415;
          zoomLevel = 7;
        }
        map.setView([newLat, newLng], zoomLevel);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);
    return null;
  };

  const handleMarkerDragStart = (point) => {
    if (!point) return;

    const initialPositions = selectedPoints.map((p) => ({
      id: p.id,
      lat: p.latitude,
      lng: p.longitude,
    }));

    point.initialPositions = initialPositions;
    point.initialPosition = { lat: point.latitude, lng: point.longitude };
  };

  const handleMarkerDrag = (event, point) => {
    const { lat: initialLat, lng: initialLng } = point.initialPosition;
    const { lat, lng } = event.target.getLatLng();

    const deltaLat = lat - initialLat;
    const deltaLng = lng - initialLng;

    const updatedPoints = selectedPoints.map((p) => {
      const initialPos = point.initialPositions.find((ip) => ip.id === p.id);
      if (initialPos) {
        return {
          ...p,
          latitude: initialPos.lat + deltaLat,
          longitude: initialPos.lng + deltaLng,
        };
      }
      return p;
    });

    updatedPoints.forEach((p) => {
      if (markersRef.current[p.id]) {
        markersRef.current[p.id].setLatLng([p.latitude, p.longitude]);
      }
    });
  };

  const handleMarkerDragEnd = () => {
    updateFilteredPointsInitialPosition(setFilteredMapPoints);

    if (filteredTrees.length > 0) {
      updateFilteredPointsInitialPosition(setFilteredTrees);
    }

    if (filteredCableSpacers.length > 0) {
      updateFilteredPointsInitialPosition(setFilteredCableSpacers);
    }

    const updatedSelectedPoints = selectedPoints.map((point) => {
      const marker = markersRef.current[point.id];
      if (marker) {
        const { lat, lng } = marker.getLatLng();
        return {
          ...point,
          latitude: lat,
          longitude: lng,
        };
      }
      return point;
    });

    setSelectedPoints(updatedSelectedPoints);

    setPointsToBeSaved((prev) => {
      const pointsSet = new Set(prev.map((p) => p.id));
      const uniquePoints = updatedSelectedPoints.filter(
        (p) => !pointsSet.has(p.id)
      );
      return [...prev, ...uniquePoints];
    });
  };

  function updateFilteredPointsInitialPosition(setFunction) {
    setFunction((prevPoints) =>
      prevPoints.map((point) => {
        const marker = markersRef.current[point.id];
        if (marker) {
          const { lat, lng } = marker.getLatLng();
          return {
            ...point,
            latitude: lat,
            longitude: lng,
            initialPosition: { lat, lng },
          };
        }
        return point;
      })
    );
  }

  useEffect(() => {
    mapPointsRef.current = mapPoints;
    treesRef.current = trees;
    cableSpacersRef.current = cableSpacers;
  }, [mapPoints, trees, cableSpacers]);

  const onDrawCreate = (e) => {
    const layer = e.layer;
    const geojson = layer.toGeoJSON();

    const polygon = turf.polygon(geojson.geometry.coordinates);

    const pointsWithinPolygon = [
      ...mapPointsRef.current.map((point) => ({ ...point, type: "poles" })),
      ...treesRef.current.map((tree) => ({ ...tree, type: "trees" })),
      ...cableSpacersRef.current.map((cableSpacer) => ({
        ...cableSpacer,
        type: "cable-spacers",
      })),
    ].filter((point) => {
      const pt = turf.point([point.longitude, point.latitude]);
      return turf.booleanPointInPolygon(pt, polygon);
    });

    setSelectedPoints(pointsWithinPolygon);
  };

  const updateDraggable = (
    pointsArray,
    selectedPoints,
    setStateFunction,
    drag
  ) => {
    const updatedPoints = pointsArray.map((point) => {
      if (selectedPoints.find((slp) => slp.id === point.id)) {
        return { ...point, draggable: drag };
      }
      return point;
    });

    setStateFunction(updatedPoints);
  };

  useEffect(() => {
    if (selectedPoints.length > 0) {
      updateDraggable(
        filteredMapPoints,
        selectedPoints.filter((point) => point.type === "poles"),
        setFilteredMapPoints,
        true
      );

      if (filteredCableSpacers.length > 0) {
        updateDraggable(
          filteredTrees,
          selectedPoints.filter((point) => point.type === "trees"),
          setFilteredTrees,
          true
        );
      }

      if (filteredTrees.length > 0) {
        updateDraggable(
          filteredCableSpacers,
          selectedPoints.filter((point) => point.type === "cable-spacers"),
          setFilteredCableSpacers,
          true
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoints]);

  const saveUpdatedMarkers = async () => {
    setLoading(true);
    try {
      const polesToBeSaved = pointsToBeSaved.filter((p) => p.type === "poles");
      const treesToBeSaved = pointsToBeSaved.filter((p) => p.type === "trees");
      const cableSpacersToBeSaved = pointsToBeSaved.filter(
        (p) => p.type === "cable-spacers"
      );

      const fetchComponentData = polesToBeSaved.map(async (p) => {
        try {
          const { data } = await api.get(`/detected-objects/${p.id}/children`);

          const updatedComponents = updateComponentPositions(data, p);

          await saveUpdatedComponents(updatedComponents);
        } catch (e) {
          console.log(
            `Error fetching or updating components for pole ${p.id}:`,
            e
          );
        }
      });

      await Promise.all(fetchComponentData);

      const updateDirectly = async (items, type) => {
        await Promise.all(
          items.map(async (item) => {
            try {
              await api.put(`/detected-objects/${item.id}`, item);
            } catch (e) {
              console.log(`Error updating ${type} ${item.id}:`, e);
            }
          })
        );
      };

      await updateDirectly(polesToBeSaved, "poles");
      await updateDirectly(treesToBeSaved, "trees");
      await updateDirectly(cableSpacersToBeSaved, "cable-spacers");

      replaceUpdatedPoints(mapPoints, polesToBeSaved, setMapPoints);

      if (treesToBeSaved.length > 0) {
        replaceUpdatedPoints(trees, treesToBeSaved, setTrees);
      }

      if (cableSpacersToBeSaved.length > 0) {
        replaceUpdatedPoints(
          cableSpacers,
          cableSpacersToBeSaved,
          setCableSpacers
        );
      }

      setMessage("Os pontos foram salvos com sucesso!");
      toggleModalMessage();
      handleClearAll();
    } catch (e) {
      console.log("Error saving updated markers:", e);
      setMessage("Ocorreu um erro ao salvar os pontos!");
      toggleModalMessage();
    } finally {
      toggleModalConfirmation();
    }

    setLoading(false);
  };

  const updateComponentPositions = (components, pole) => {
    return components.map((i) => ({
      ...i,
      latitude: pole.latitude,
      longitude: pole.longitude,
    }));
  };
  
  const saveUpdatedComponents = async (components) => {
    await Promise.all(components.map((component) => updateComponent(component)));
  };
  
  const updateComponent = async (component) => {
    try {
      return await api.put(`/detected-objects/${component.id}`, component);
    } catch (e) {
      console.error(`Error updating component ${component.id}:`, e);
    }
  };

  const replaceUpdatedPoints = (originalArray, updatedPoints, setFunction) => {
    const updatedPointsMap = new Map(
      updatedPoints.map((point) => [point.id, point])
    );
    setFunction(
      originalArray.map((point) => updatedPointsMap.get(point.id) || point)
    );
  };

  const getPreviewImagesMarkers = (points, color) => {
    let pointMarkers = [];

    points.map((point) => {
      pointMarkers.push(
        <Marker
          key={Math.random()}
          icon={createCustomIcon(color)}
          position={[point.latitude, point.longitude]}
          style={{ cursor: "default" }}
        />
      );
      return null;
    });
    return pointMarkers;
  };

  const ZoomUpdate = () => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCenterAuto(false);
        setZoomLevelInfo(mapEvents.getZoom(), 7);
      },
    });
    return null;
  };

  useEffect(() => {
    if (map != null) {
      setNewPointPosition(map.getCenter());
    }
  }, [map, newPointPreviewMap, setNewPointPosition]);

  const handleClearAll = () => {
    if (drawnLayersRef.current != null) {
      drawnLayersRef.current.clearLayers();
    }

    setSelectedPoints([]);
    setFilteredMapPoints(mapPoints);
    setFilteredTrees(trees);
    setFilteredCableSpacers(cableSpacers);
    setPointsToBeSaved([]);
  };

  useEffect(() => {
    if (!moveMapPoints) {
      handleClearAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveMapPoints]);

  const startDrawingPolygon = () => {
    const drawControl = new L.Draw.Polygon(map);
    drawControl.enable();
  };

  return (
    <>
      {moveMapPoints && (
        <ModalDragMapPoints
          clearAll={handleClearAll}
          saveUpdatedMarkers={toggleModalConfirmation}
          startDrawingPolygon={startDrawingPolygon}
          offCanvas={offCanvas}
        ></ModalDragMapPoints>
      )}

      {modalConfirmation && (
        <ModalConfirmation
          toggle={toggleModalConfirmation}
          modal={modalConfirmation}
          text={"Deseja salvar os pontos?"}
          confirmFunction={saveUpdatedMarkers}
        />
      )}

      {modalMessage && (
        <ModalMessage
          isOpen={modalMessage}
          message={message}
          toggle={toggleModalMessage}
        />
      )}

      <MapContainer
        center={[-21.7729814283363, -43.364476638112734]}
        zoom={zoom} // Passe o zoom como prop
        scrollWheelZoom={true}
        style={{ width: "100%", height: "100%" }}
        ref={setMap}
        zoomControl={false}
      >
        <ZoomUpdate />
        <TileLayer
          attribution={'<a href="http://www.google.com">Google&copy</a>'}
          url={baseMapSource.url}
          subdomains={baseMapSource.subDomains}
          maxZoom={21}
        />
        {moveMapPoints && (
          <FeatureGroup ref={drawnLayersRef}>
            <EditControl
              position="topright"
              onCreated={(e) => onDrawCreate(e)}
              draw={{
                rectangle: false,
                polygon: false,
                polyline: false,
                circle: false,
                marker: false,
                circlemarker: false,
              }}
              edit={{
                remove: false,
                edit: false,
              }}
            />
          </FeatureGroup>
        )}

        {getPointsMarkers(
          filteredMapPoints,
          selectedPointIcon,
          defaultPointColor,
          "poles"
        )}

        {enableTreePointsView &&
          getPointsMarkers(
            filteredTrees,
            selectedTreeIcon,
            defaultTreePointColor,
            "trees"
          )}

        {enableCableSpacerPointsView &&
          getPointsMarkers(
            filteredCableSpacers,
            selectedCableSpacerIcon,
            defaultCableSpacersColor,
            "cable-spacers"
          )}

        {newPointPreviewMap && (
          <DraggableMarker
            key={Math.random()}
            center={map.getCenter()}
            icon={newPoleIcon}
            newPointPosition={newPointPosition}
            setNewPointPosition={setNewPointPosition}
          />
        )}

        {carPath &&
          carPathMapPoints?.map(({ images }) => (
            <Polyline
              key={Math.random()}
              positions={images?.map((coord) => [
                coord.latitude,
                coord.longitude,
              ])}
              color="blue"
            />
          ))}

        {previewImagesVisible &&
          getPreviewImagesMarkers(previewImagesNewPoint, "#FF2C2C")}

        {/* Begin areas_of_interest */}
        {areasOfInterestActive &&
          areaOfInterest &&
          areaOfInterest.map((point, cont = 0) => {
            let contPoint = 0;

            point.forEach((elt) => {
              if (!centerTag.latMax || elt[0] < centerTag.latMax)
                centerTag.latMax = elt[0];
              if (!centerTag.latMin || elt[0] > centerTag.latMin)
                centerTag.latMin = elt[0];
              if (!centerTag.lngMax || elt[1] < centerTag.lngMax)
                centerTag.lngMax = elt[1];
              if (!centerTag.lngMin || elt[1] > centerTag.lngMin)
                centerTag.lngMin = elt[1];
              contPoint++;
            });

            let recenter = null;
            if (
              areaOfInterest.length === 1 ||
              (cont++ === areaOfInterest.length - 1 &&
                contPoint === point.length)
            ) {
              recenter = (
                <RecenterAutomatically
                  key={Math.random()}
                  lat={(centerTag.latMax + centerTag.latMin) / 2}
                  lng={(centerTag.lngMax + centerTag.lngMin) / 2}
                />
              );
            }
            return (
              <div key={Math.random()}>
                <Polygon
                  key={Math.random()}
                  pathOptions={{
                    color: colorLayersList[cont][1]
                      ? colorLayersList[cont][1]
                      : "purple",
                    fillOpacity: 0,
                  }}
                  positions={point}
                />
                {recenter}
              </div>
            );
          })}
        {/* End areas_of_interest */}
      </MapContainer>
    </>
  );
};

export default MapContainerComponent;
